import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../layouts'
import { Body, SizedText, Canon } from '../components/elements/typography'
import Grid from '../components/elements/grid'
import Breadcrumbs from "../components/molocules/breadcurmbs"
import Icon from "../components/elements/icon"
import backIcon from "../theme/icons/back.svg"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const slugArray = frontmatter.slug.split("/")

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>    
      <Grid.FullWidthSection>
        <Grid.Block start={[2,2,2,2,2,3]} span={[12,12,12,12,12,10]} css={{
          padding: `4rem 0 8rem 0`,
        }}>
          
          <Breadcrumbs links={[
            { path: "/", text: "Home" },
            { path: "/about/", text: "About Us" },
            { path: "/about/news/", text: "News" },
            { path: `/about/news/${slugArray.slice(-2)[0]}/`, text: slugArray.slice(-2)[0] },
            { path: `/about/news/${slugArray.slice(-2)[0]}/${slugArray.slice(-1)[0]}`, text: frontmatter.title  }
          ]} />

          <Link to="/about/news">
            <span css={{paddingRight: `0.5rem`, fontSize: `1.424rem`}}><Icon icon={backIcon} overrides={{verticalAlign: `-0.44rem !important`}}/></span>
            <span>Go Back to News</span>
          </Link>
          
          <div className="blog-post-container">
            <div className="blog-post">
              <h2 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  color: `#7f8c8d`, margin: `3rem 0 0 0`, 'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
                <SizedText as="span" smallSize="1" largeSize="1.225">
                {frontmatter.date} - {frontmatter.location}
                </SizedText>
              </h2>
              <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  margin: `0 0 2rem 0`, 'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
                <SizedText as="span" smallSize="2.281" largeSize="4.624">
                  {frontmatter.title}
                </SizedText>
              </h1>
              
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
                css={{
                  'h1': {
                    color: `#2980b9`,
                  }
                }}
              />
            </div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        location
      }
    }
  }
`