import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import Grid from '../../elements/grid'
import t from "../../../theme/tokens"

const Breadcrumbs = ({links}) =>  {

  const listItems = links.map((i) =>
    <li key={i.path.toString()}>
      <Link
        to={i.path}
      >
        {i.text}
      </Link>
    </li>
  )

  return (
    <>
      <Grid.Block
        className="breadcrumbs" 
        as="nav"
        start={[0,0,2,2,2,3]} 
        span="12" 
        aria-label="breadcrumb" 
        css={{
          display: `none`,

          [`@media (min-width: ${t.breakpoints[3]}px)`]: {
            display: `block`,

            'ol': { listStyle: 'none', padding: '0', margin: '1rem 0 2.5rem 0', fontSize: `14px` },
            'li': { display: 'inline', margin: '0.25em 0 0.5em', padding: '0' },
            'li + li::before': { 
              display: `inline-block`,
              margin: `0 0.25em`,
              transform: `rotate(15deg)`,
              borderRight: `1px solid currentColor`,
              height: `0.8em`,
              content: `""`,
            },
            '[aria-current="page"]': {
              color: `#000`,
              fontWeight: `700`,
              textDecoration: `none`,
            },
            'li a': { 
              textDecoration: "none", 
              color: "inherit",
              '&:hover': { textDecoration: "underline" }
            },
            
          }
        }}
      >
        {/* <HiddenSpan>You are here</HiddenSpan> */}
        <ol>
          {listItems}
        </ol>
      </Grid.Block>
    </>
  )
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Breadcrumbs